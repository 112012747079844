// TABLA LICENCIAS

table#pricing{
    border-radius: 7px;
    box-shadow: 5px 5px 0 rgba(0,0,0,0.05);
    font-weight: 400;
    td{
        padding: 5px 10px;
        background-color: #fff;
        color: #777;
        font-size: 17px;
    }
    .empty-corner{
        background: none !important;
        color: #fff;
    }
    .ul-corner{
        border-radius: 7px 0 0 0;
    }
    .ur-corner{
        border-radius: 0 7px 0 0;
    }
    .br-corner{
        border-radius: 0 0 7px 0;
    }
    .bl-corner{
        border-radius: 0 0 0 7px;
    }
    .license-name{
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 0;
        text-transform: uppercase;
        background-color: #5CC9DF;
        color: #fff;
    }
    .license-feature{
        background-color: #5CC9DF;
        color: #fff;
        font-size: 14px;
    }
    .license-price{
        background-color: #5CC9DF;
        color: #fff;
        font-size: 48px;
        font-weight: 100;
        line-height: 48px;
    }
    .feature-row-blue{
        .license-feature{
            background-color: #fff;
            color: #777;
        }
        td{
            background-color: #5CC9DF;
            color: #fff;
        }
    }
    .column-tile-blue{
        background-color: #5CC9DF;
        color: #fff;
        a.btn-qubico{
            background-color: #fff;
            color: #5CC9DF;
        }
    }
    .column-tile-white{
        background-color: #fff;
        color: #777;
    }
    .license-sentence{
        font-family: "Times New Roman", Times, serif;
    }
    .price-detail{
        font-size: 30px;
    }
}
